import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/app/apps/main/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/apps/main/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/apps/main/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/apps/main/node_modules/react-multi-carousel/lib/styles.css");
;
import(/* webpackMode: "eager" */ "/app/apps/main/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager", webpackExports: ["Banners"] */ "/app/apps/main/src/entities/banner/ui/banners.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/main/src/entities/game/ui/game-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GamesPane"] */ "/app/apps/main/src/entities/game/ui/games-pane.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TilesLayout"] */ "/app/apps/main/src/entities/game/ui/tiles-layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/main/src/entities/providers/ui/providers-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QABCarousel"] */ "/app/apps/main/src/entities/qab/ui/qab-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LayoutDesktop"] */ "/app/apps/main/src/screens/home/ui/layout-desktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LayoutMobile"] */ "/app/apps/main/src/screens/home/ui/layout-mobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/app/apps/main/src/shared/lib/apollo/apolloWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/main/src/shared/lib/toast/toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BetDividerMobile"] */ "/app/apps/main/src/shared/ui/bet-devider-mobile/bet-divider-mobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MultiBetDividerMobile"] */ "/app/apps/main/src/shared/ui/bet-devider-mobile/multi-bet-divider-mobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ExpandableInput"] */ "/app/apps/main/src/shared/ui/expandble-input/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hint"] */ "/app/apps/main/src/shared/ui/hint/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalMobile"] */ "/app/apps/main/src/shared/ui/modal-mobile/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Modal"] */ "/app/apps/main/src/shared/ui/modal/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SeoBlock"] */ "/app/apps/main/src/shared/ui/seo-block/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SwipingPane"] */ "/app/apps/main/src/shared/ui/swiping-pane/swiping-pane.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TextInput"] */ "/app/apps/main/src/shared/ui/text-input/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserPanel"] */ "/app/apps/main/src/shared/ui/user-panel/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FavoriteGames"] */ "/app/apps/main/src/widgets/casino/favorite-games/ui/favorite-games.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FavoriteGamesPreviewPane"] */ "/app/apps/main/src/widgets/casino/favorite-games/ui/preview-pane.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FavoriteGamesPreviewTiles"] */ "/app/apps/main/src/widgets/casino/favorite-games/ui/preview-tiles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WinnersSlider"] */ "/app/apps/main/src/widgets/winners/ui/winners-slider.tsx");
