'use client';

import { SeoConfigVariant, SeoTextVariables, replaceVariablesInTemplate } from '@/entities/seo';
import { useSeoConfig } from '@/entities/seo/model/use-seo-config';
import { SeoBlock } from '@/shared/ui/seo-block';

type Props = {
  seoConfigVariant: SeoConfigVariant;
  variables?: SeoTextVariables;
  className?: string;
  queryArgs?: Record<string, unknown>;
  withExpand?: boolean;
};

export const DefaultSeoText = ({
  seoConfigVariant,
  variables,
  queryArgs,
  className,
  withExpand = true
}: Props) => {
  const { seo, loading, error } = useSeoConfig(seoConfigVariant, { variables: queryArgs });

  if (loading || error || !seo) return null;

  return (
    <SeoBlock
      title={replaceVariablesInTemplate(seo.titleH1, variables)}
      text={replaceVariablesInTemplate(seo.seoText, variables)}
      className={className}
      withExpand={withExpand}
    />
  );
};
