import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { priceFormat } from '@dante/price-formatters';

import { RoutesConfig } from '@/shared/routes/config';
import { Winner } from '../lib/get-winners';

type Props = {
  winner: Winner;
  currency: string;
};

const LIMIT = 100000;
const ICON_FALLBACK = '/games/fallback.png';

export const WinnersItem = React.forwardRef<HTMLAnchorElement, Props>(
  ({ winner, currency }, ref) => {
    const { amount, slug, name, icon } = winner;
    const isWinner = amount >= LIMIT;

    return (
      <Link
        ref={ref}
        href={`${RoutesConfig.casinoGames}/${slug}`}
        target="_self"
        className={classNames(
          'relative flex flex-none items-center justify-between gap-x-1',
          'h-6t w-[214px] rounded-s p-xxs md:h-12t md:w-[456px]',
          isWinner
            ? 'bg-gradient-top-wins hover:bg-brand-purple hover:bg-none '
            : 'bg-background-card hover:bg-background-tag',
          {
            'animate-newHorizontalListItem': winner.animate
          }
        )}
      >
        <div className="flex min-w-0 items-center gap-1 md:gap-2">
          <img
            className="h-4t w-4t overflow-hidden rounded-xxs md:h-[104px] md:w-[104px]"
            src={icon?.url ?? winner.providerIcon ?? ICON_FALLBACK}
            alt={icon?.alt ?? winner.providerIconAlt ?? name}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = ICON_FALLBACK;
            }}
          />
          <div className="flex min-w-0 flex-1 flex-col gap-1/2 text-text-primary md:w-[264px]">
            <span className="typography-mobile-body overflow-hidden text-ellipsis text-nowrap md:typography-title-regular">
              {name}
            </span>
            <span className="typography-mobile-button md:typography-headline-3">
              {priceFormat(amount, currency, 'letter', 0)}
            </span>
          </div>
        </div>
        {isWinner && (
          <img
            src="/emojis/Fire.svg"
            alt="fire"
            className="h-[16px] w-[16px] md:mr-2 md:h-[32px] md:w-[32px]"
          />
        )}
      </Link>
    );
  }
);

WinnersItem.displayName = 'WinnersItem';
