'use client';

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/mousewheel';
import { getDataTestId } from '@/shared/lib/get-data-testid';
import { useScopedI18n } from '@/shared/locales';
import { QABItem } from './qab-item';

interface QABarItemData {
  id: string;
  text: string;
  image?: { url: string };
  href: string;
}

interface QABarCarouselProps {
  items: QABarItemData[];
  className?: string;
}

export const QABCarousel: React.FC<QABarCarouselProps> = ({ items, className }) => {
  const t = useScopedI18n('main');
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    setIsReady(true);
  }, []);

  return (
    <div className={classNames(className, 'relative select-none')}>
      {isReady ? (
        <Swiper
          className="!mr-[-16px] !pr-2 md:!mr-[auto] md:!pr-[auto]"
          spaceBetween={8}
          slidesPerView="auto"
          modules={[Mousewheel]}
          direction="horizontal"
          mousewheel={{ forceToAxis: true, releaseOnEdges: true, sensitivity: 1.5 }}
          breakpoints={{
            982: {
              spaceBetween: 16
            }
          }}
        >
          {items.map(({ id, text, image, href }, index) => (
            <SwiperSlide key={index} className="!w-auto select-none">
              <QABItem
                title={t(text as keyof typeof t)}
                image={image?.url}
                link={href}
                dataTestId={getDataTestId({
                  block: 'qaBar',
                  nameForTargetElement: 'QABarItem',
                  modification: id
                })}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="flex gap-2 overflow-hidden">
          {items.map(({ id, text, image, href }, index) => (
            <QABItem
              key={index}
              title={t(text as keyof typeof t)}
              image={image?.url}
              link={href}
              dataTestId={getDataTestId({
                block: 'qaBar',
                nameForTargetElement: 'QABarItem',
                modification: id
              })}
            />
          ))}
        </div>
      )}
      <div className="z-10 absolute inset-y-0 right-0 top-0 z-1 hidden h-full w-8t bg-gradient-to-l from-[rgba(13,14,15,1)] to-[rgba(13,14,15,0)] md:block"></div>
    </div>
  );
};
