import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { getButtonStyles } from '../lib/get-button-styles';
import { Banner } from '@/__generated__/types';

type Props = Omit<Banner, 'typename'> & {
  className?: string;
};

const DEFAULT_GRADIENT_START = '#753bbd';
const DEFAULT_GRADIENT_END = '#00c7b1';

export const BannerItem: React.FC<Props> = ({
  image,
  title,
  text,
  buttonText,
  buttonColor,
  buttonHoverColor,
  buttonTextColor,
  buttonTextHoverColor,
  gradientStartColor,
  gradientEndColor,
  href,
  className: exClassName
}) => {
  const showButton = buttonText && href;
  const buttonColors =
    '!text-bannerButtonTextColor !bg-bannerButtonColor hover:!text-bannerButtonTextHoverColor hover:!bg-buttonColor hover:!bg-bannerButtonHoverColor hover:!border-bannerButtonColor hover:!border-bannerButtonHoverColor';
  const buttonStyle = getButtonStyles({
    buttonColor,
    buttonHoverColor,
    buttonTextColor,
    buttonTextHoverColor
  });

  return (
    <Wrapper
      href={href}
      style={{
        backgroundImage: `linear-gradient(to top right, ${gradientStartColor ?? DEFAULT_GRADIENT_START}, ${gradientEndColor ?? DEFAULT_GRADIENT_END})`
      }}
      className={classNames(
        exClassName,
        'relative w-full overflow-hidden',
        'block h-[176px] rounded-s md:h-[348px] md:rounded-xm',
        'px-[16px] py-[18px] md:px-5 md:py-5'
      )}
    >
      <div
        className={classNames(
          'flex h-full w-[150px] flex-col gap-xxs',
          'relative z-3',
          'md:w-full md:max-w-[400px] ',
          'banner-item-content'
        )}
      >
        <h3 className={classNames('typography-mobile-headline-bold md:typography-headline')}>
          {title}
        </h3>
        <p className={classNames('typography-mobile-body md:typography-footnote')}>{text}</p>
        {showButton && (
          <button
            className={classNames(
              'typography-mobile-button-small mt-auto h-[40px] w-fit rounded-xxs px-[12px] py-[12px]',
              'md:typography-button-semibold md:h-[54px] md:rounded-s md:px-[80px] md:py-2',
              buttonColors
            )}
            style={buttonStyle}
          >
            {buttonText}
          </button>
        )}
      </div>
      <img
        src={image.url ?? undefined}
        alt={title}
        className="absolute left-0 top-0 h-full w-full object-contain object-right md:hidden"
      />
    </Wrapper>
  );
};

type WrapperProps = {
  href?: string | null;
  className: string;
  style: React.CSSProperties;
};

const Wrapper: React.FC<PropsWithChildren<WrapperProps>> = ({
  href,
  className,
  style,
  children
}) => {
  return href ? (
    <a className={className} style={style} href={href}>
      {children}
    </a>
  ) : (
    <div className={className} style={style}>
      {children}
    </div>
  );
};
