'use client';
import React, { useEffect, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import classNames from 'classnames';

import { useScopedI18n } from '@/shared/locales/client';
import { Skeleton } from '@/shared/ui';
import { Device } from '@/entities/game';
import { getDataTestId } from '@/shared/lib/get-data-testid';
import { Banner } from '@/__generated__/types';

import { BannerArrow } from './banner-arrow';
import { BannerItem } from './banner-item';

import 'swiper/css';
import 'swiper/css/navigation';
import './styles.css';

const marginsClassname = 'mb-3 md:mb-5';

type Props = {
  loading?: boolean;
  banners?: Banner[] | null;
  device: Device;
};

export const Banners: React.FC<Props> = ({ banners, loading, device }) => {
  const t = useScopedI18n('main');
  const swiperRef = useRef<SwiperClass>(null);
  const [isReady, setIsReady] = useState(false);

  /** Duplicates two banners for proper loop work */
  const duplicatesTwoBanners = (banners: Banner[]) => (banners.length === 2 ? [...banners] : []);

  const slidePrev = () => {
    swiperRef.current?.slidePrev();
  };

  const slideNext = () => {
    swiperRef.current?.slideNext();
  };

  useEffect(() => {
    setIsReady(true);
  }, []);

  if (loading) {
    return (
      <Skeleton className="h-16t w-full rounded-md px-2 md:h-45t md:px-12t">
        <Skeleton
          color="dark"
          className="mb-1 h-12px w-14t rounded-2xl md:mb-[22px] md:h-[44px] md:w-[500px]"
        />
        <Skeleton
          color="dark"
          className="mb-[63px] h-12px w-12t rounded-2xl md:mb-[133px] md:h-[44px] md:w-45t"
        />
        <Skeleton color="dark" className="h-3t w-10t rounded-md md:h-5t md:w-18t" />
      </Skeleton>
    );
  }

  if (!banners?.length) return null;

  if (banners.length === 1 || (device === 'mobile' && !isReady))
    return (
      <div
        id="banner"
        className={classNames(
          marginsClassname,
          'mx-auto w-[calc(100vw-32px)] md:w-[calc(100%-24px)] md:pb-2',
          `${isReady ? 'banners-fade-in' : 'banners-hidden'}`
        )}
        data-testid={getDataTestId({
          block: 'banners',
          nameForTargetElement: 'slide',
          modification: '0'
        })}
      >
        <BannerItem
          title={t(banners[0].title as any)}
          text={t(banners[0].text as any)}
          buttonText={t(banners[0].buttonText as any)}
          buttonColor={banners[0].buttonColor}
          buttonHoverColor={banners[0].buttonHoverColor}
          buttonTextColor={banners[0].buttonTextColor}
          buttonTextHoverColor={banners[0].buttonTextHoverColor}
          gradientStartColor={banners[0].gradientStartColor}
          gradientEndColor={banners[0].gradientEndColor}
          href={banners[0].href}
          image={banners[0].image}
          className="md:px-[104px]"
        />
      </div>
    );

  return (
    <>
      {isReady ? (
        <div
          className={classNames(
            'banners-overflow-container md:pb-2',
            `${isReady ? 'banners-fade-in' : 'banners-hidden'}`,
            marginsClassname
          )}
        >
          <div id="banner" className="banners-container">
            <BannerArrow onClick={slideNext} direction="right" />
            <BannerArrow onClick={slidePrev} direction="left" />
            <Swiper
              speed={600}
              breakpoints={{
                320: {
                  slidesPerView: 1.005,
                  spaceBetween: 8
                },
                640: {
                  slidesPerView: 1.5,
                  spaceBetween: 8
                },
                // when window width is >=982 px
                982: {
                  slidesPerView: 2,
                  spaceBetween: 16
                }
              }}
              modules={[Navigation, Pagination]}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              loop
              pagination={{
                el: '#containerForBullets',
                renderBullet: function (index, className) {
                  const customPaginationStyle =
                    device === 'desktop' && !!duplicatesTwoBanners(banners).length;
                  return renderToStaticMarkup(
                    <button
                      className={classNames(
                        className,
                        'transition duration-300 ease-linear',
                        'h-[6px]  w-[6px] rounded-full bg-white opacity-50',
                        { [`custom-bullet-${index}`]: customPaginationStyle }
                      )}
                    />
                  );
                },
                clickable: true
              }}
              className="md:rounded-xm"
            >
              {[...banners, ...duplicatesTwoBanners(banners)].map((item, index) => (
                <SwiperSlide
                  key={index}
                  data-testid={getDataTestId({
                    block: 'banners',
                    nameForTargetElement: 'slide',
                    modification: index.toString()
                  })}
                >
                  <BannerItem
                    title={t(item.title as any)}
                    text={t(item.text as any)}
                    buttonText={t(item.buttonText as any)}
                    buttonColor={item.buttonColor}
                    buttonHoverColor={item.buttonHoverColor}
                    buttonTextColor={item.buttonTextColor}
                    buttonTextHoverColor={item.buttonTextHoverColor}
                    gradientStartColor={item.gradientStartColor}
                    gradientEndColor={item.gradientEndColor}
                    href={item.href}
                    image={item.image}
                    className="banner-item"
                  />
                </SwiperSlide>
              ))}
              <div
                id="containerForBullets"
                className="absolute left-[50%] m-0 -translate-x-1/2 justify-center space-x-xxxs"
              ></div>
            </Swiper>
          </div>
        </div>
      ) : (
        <div className="banners-not-ready">
          {banners.slice(0, 2).map((item, index) => (
            <div
              key={index}
              className={classNames(
                index === 0
                  ? 'banners-not-ready__first-banner'
                  : 'banners-not-ready__second-banner',
                marginsClassname
              )}
            >
              <BannerItem
                title={t(item.title as any)}
                text={t(item.text as any)}
                buttonText={t(item.buttonText as any)}
                buttonColor={item.buttonColor}
                buttonHoverColor={item.buttonHoverColor}
                buttonTextColor={item.buttonTextColor}
                buttonTextHoverColor={item.buttonTextHoverColor}
                gradientStartColor={item.gradientStartColor}
                gradientEndColor={item.gradientEndColor}
                href={item.href}
                image={item.image}
                className="banner-item"
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};
