import React from 'react';
import classNames from 'classnames';
import { getDataTestId } from '@/shared/lib/get-data-testid';
import { ArrowNavButton } from '@/shared/ui';

type BannerArrowProps = {
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  direction: 'right' | 'left';
  hidden?: boolean;
};

export const BannerArrow: React.FC<BannerArrowProps> = ({ onClick, direction, hidden }) => (
  <ArrowNavButton
    onClick={onClick}
    className={classNames(
      'text-secondary hidden md:block',
      'top-half z-2 -translate-y-half cursor-pointer drop-shadow-lg md:absolute',
      'flex items-center justify-center',
      {
        '!hidden': hidden,
        'left-s -scale-x-100': direction === 'left',
        'right-s': direction === 'right'
      }
    )}
    size="l"
    variant="light"
    dataTestId={getDataTestId({
      block: 'banners',
      nameForTargetElement: 'navigation',
      modification: direction === 'right' ? 'next' : 'previous'
    })}
  />
);
