'use client';

import React from 'react';

import { DefaultSeoText } from '@/widgets/default-seo-text';

type Props = {
  children?: React.ReactNode;
};

export const LayoutMobile: React.FC<Props> = ({ children }) => {
  return (
    <div className="mx-auto flex w-full flex-col gap-3 px-2 md:gap-5 md:px-0 md:pr-3">
      {children}
      <DefaultSeoText className="mt-2t" seoConfigVariant="seoHomeConfig" />
    </div>
  );
};
