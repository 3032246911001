'use client';

import React from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/mousewheel';

import { Provider } from '@/entities/providers/types';
import { getDataTestId } from '@/shared/lib/get-data-testid';

import ProviderCard from './provider-card';
import './styles.css';

interface ProvidersCarouselProps {
  items: Provider[];
  className?: string;
  cardClassName?: string;
  withShadow?: boolean;
  carouselClassName?: string;
}

const ProvidersCarousel: React.FC<ProvidersCarouselProps> = ({
  items,
  className,
  cardClassName,
  withShadow = true,
  carouselClassName
}) => {
  return (
    <div className={classNames(className, 'relative select-none')}>
      <Swiper
        className={classNames(carouselClassName, '!mr-[-16px] !pr-2 md:!mr-[auto] md:!pr-[auto]')}
        spaceBetween={8}
        slidesPerView="auto"
        modules={[Mousewheel, Navigation]}
        direction="horizontal"
        navigation={{
          nextEl: `.provider-next`,
          prevEl: `.provider-prev`
        }}
        mousewheel={{ forceToAxis: true, releaseOnEdges: true, sensitivity: 1.5 }}
        breakpoints={{
          982: {
            spaceBetween: 16
          }
        }}
      >
        {items.map(({ provider, bigLogo }, index) => (
          <SwiperSlide
            key={index}
            className="!w-auto select-none"
            data-testid={getDataTestId({
              block: 'providers',
              nameForTargetElement: 'ProviderCard',
              modification: String(index)
            })}
          >
            <ProviderCard name={provider} image={bigLogo?.url || ''} className={cardClassName} />
          </SwiperSlide>
        ))}
      </Swiper>
      {withShadow && (
        <div className="z-10 absolute inset-y-0 bottom-0 right-0  top-auto z-1 hidden h-[120px] w-8t bg-gradient-to-l from-[rgba(13,14,15,1)] to-[rgba(13,14,15,0)] md:block"></div>
      )}
    </div>
  );
};

export default ProvidersCarousel;
