import { Maybe } from 'graphql/jsutils/Maybe';

export const getButtonStyles = ({
  buttonColor,
  buttonHoverColor,
  buttonTextColor,
  buttonTextHoverColor
}: {
  buttonColor: Maybe<string>;
  buttonHoverColor: Maybe<string>;
  buttonTextColor: Maybe<string>;
  buttonTextHoverColor: Maybe<string>;
}) => {
  const result: Record<string, string> = {};

  if (buttonTextColor) {
    result['--banner-button-text-color'] = buttonTextColor;
  }

  if (buttonColor) {
    result['--banner-button-color'] = buttonColor;
  }

  if (buttonHoverColor) {
    result['--banner-button-hover-color'] = buttonHoverColor;
  }

  if (buttonTextHoverColor) {
    result['--banner-button-text-hover-color'] = buttonTextHoverColor;
  }

  return result;
};
