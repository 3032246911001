import React from 'react';
import classNames from 'classnames';

type Props = {
  title: string;
  image?: string;
  link: string;
  className?: string;
  dataTestId?: string;
};

export const QABItem = React.forwardRef<HTMLDivElement, Props>(
  ({ className: externalClassName, title, image, link, dataTestId }, ref) => (
    <div
      data-testid={dataTestId || ''}
      ref={ref}
      className={classNames(
        'relative h-[102px] w-[140px] flex-none rounded-2 pb-1/2 transition-colors duration-200 ease-in-out md:h-[146px] md:w-[194px] md:pb-0 lg:h-[161px] lg:w-[220px]',
        externalClassName
      )}
    >
      <a href={link} className="group">
        <img
          alt={title}
          src={image}
          className="h-[80px] w-[140px] rounded-2 object-cover object-center md:h-[112px] md:w-[194px] lg:h-[127px] lg:w-[220px]"
        />
        <span className="typography-mobile-title-bold mt-1/2 block text-text-primary md:typography-desktop-headline-3 group-hover:text-primary md:mt-1">
          {title}
        </span>
      </a>
    </div>
  )
);

QABItem.displayName = 'QABItem';
