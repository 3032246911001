'use client';

import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Autoplay } from 'swiper/modules';

import { getCurrencySymbol } from '@/shared/lib/price-formatters';

import { Winner } from '../lib/get-winners';
import { WinnersItem } from './item';
import { Overlay } from './overlay';

import 'swiper/css';
import 'swiper/css/mousewheel';
import 'swiper/css/autoplay';

type Props = {
  winners: Winner[];
};

export const WinnersSlider: React.FC<Props> = ({ winners }) => {
  const currencySymbol = getCurrencySymbol('INR');

  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    setIsReady(true);
  }, []);

  return (
    <>
      {isReady ? (
        <Swiper
          className="overflow-wrappers md:left-0 md:w-full"
          wrapperClass="pl-2 md:pl-0"
          spaceBetween={8}
          slidesPerView="auto"
          modules={[Mousewheel, Autoplay]}
          direction="horizontal"
          mousewheel={{ forceToAxis: true, releaseOnEdges: true, sensitivity: 1.5 }}
          breakpoints={{
            982: {
              spaceBetween: 16
            }
          }}
          autoplay={{
            pauseOnMouseEnter: true,
            reverseDirection: true
          }}
          loop
        >
          {winners.map((winner) => (
            <SwiperSlide key={`${winner.id}_${winner.amount}`} className="!w-auto">
              <WinnersItem winner={winner} currency={currencySymbol} />
            </SwiperSlide>
          ))}
          <Overlay className="right-0" />
          <Overlay className="left-0 rotate-180" />
        </Swiper>
      ) : (
        <div className="flex gap-2 overflow-hidden">
          {winners.map((winner) => (
            <WinnersItem
              key={`${winner.id}_${winner.amount}`}
              winner={winner}
              currency={currencySymbol}
            />
          ))}
        </div>
      )}
    </>
  );
};
