import React from 'react';
import classNames from 'classnames';

type Props = {
  className?: string;
};

export const Overlay: React.FC<Props> = ({ className }) => {
  return (
    <div
      className={classNames(
        'pointer-events-none absolute bottom-0 top-0 z-1 hidden w-12t bg-gradient-background-vertical md:block',
        className
      )}
    ></div>
  );
};
